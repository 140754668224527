import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { defaultFormData, languages, phoneCodes } from "./utils/constants";
import axios from "axios";
import PasswordField from "./Components/PasswordField";

const PartnerRegistrationForm = () => {
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [uploadedFileNames, setUploadedFileNames] = useState({});

  const [language, setLanguage] = useState("en");
  const [formData, setFormData] = useState(defaultFormData);
  const [loading, setLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleVendorDetailChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      vendor_details: {
        ...prevState.vendor_details, // Spread the existing vendor_details
        [name]: value, // Update the specific field inside vendor_details
      },
    }));
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      vendor_details: {
        ...prevState.vendor_details, // Spread the existing vendor_details
        address: {
          ...prevState.vendor_details.address, // Spread the existing address
          [name]: value, // Update the specific field inside address
        },
      },
    }));
  };

  const handleCoordinatesChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      vendor_details: {
        ...prevState.vendor_details, // Spread the existing vendor_details
        address: {
          ...prevState.vendor_details.address, // Spread the existing address
          coordinates: {
            ...prevState.vendor_details.address.coordinates, // Spread the existing coordinates
            [name]: value, // Update the specific field inside coordinates
          },
        },
      },
    }));
  };

  const scrollToElement = (elementId, title) => {
    const element = document.getElementById(elementId);
    if (element && title !== "Owner Id or Passport") {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleFileChange = async (e, title) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      setLoading(true); // Show loader
      try {
        const url = await uploadSingleFileToApi(
          file,
          "https://api.yafiapp.com/upload/partnerweb/uploadSingle",
          "url"
        );

        // Update the uploaded file name in state using 'title' or 'name' as the key
        setUploadedFileNames((prevNames) => ({
          ...prevNames,
          [title]: file.name, // Use title as a key for each uploaded file
        }));

        const newFile = {
          document_type: title,
          media_type: file?.type === "application/pdf" ? "pdf" : "picture", // Check if it's a PDF or an image
          media_url: url,
        };

        setFormData((prevState) => ({
          ...prevState,
          kyc: {
            ...prevState.kyc,
            documents: [...prevState.kyc.documents, newFile], // Append newFile to documents array
          },
        }));
      } catch (error) {
        console.error("File upload failed:", error);
      } finally {
        setLoading(false); // Hide loader

        // Give the UI time to update before scrolling
        setTimeout(() => {
          scrollToElement("registrationCardID", title);
        }, 100); // Adjust timeout duration as needed
      }
    }
  };

  const handleBannerFileUpload = async (e, title) => {
    const { files } = e.target;
    const file = files[0];

    if (file) {
      setLoading(true); // Show loader
      try {
        const url = await uploadSingleFileToApi(
          file,
          "https://api.yafiapp.com/upload/partnerweb/uploadSingle",
          "url"
        );

        // Update the uploaded file name in state using 'title' or 'name' as the key
        setUploadedFileNames((prevNames) => ({
          ...prevNames,
          [title]: file.name, // Use title as a key for each uploaded file
        }));

        setFormData((prevState) => ({
          ...prevState,
          vendor_details: {
            ...prevState.vendor_details, // Spread the existing vendor_details
            banner_image: url, // Update the specific field inside vendor_details
          },
        }));
      } catch (error) {
        console.error("File upload failed:", error);
      } finally {
        setLoading(false); // Hide loader

        // Give the UI time to update before scrolling
        setTimeout(() => {
          scrollToElement("registrationCardID", title);
        }, 100); // Adjust timeout duration as needed
      }
    }
  };

  const uploadSingleFileToApi = async (file, apiUrl, dataKey) => {
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true); // Show loader

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("Single file upload response:", response.data);

      if (response.data[dataKey]) {
        return response.data[dataKey];
      } else {
        throw new Error("Invalid response format: key not found");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      throw error;
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCountryCodeChange = (e) => {
    const countryCode = e.target.value;

    setFormData((prevState) => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        country_code: countryCode, // Update the country_code field
      },
    }));
  };

  const handlePhoneInputChange = (e) => {
    const digitsOnly = e.target.value.replace(/\D/g, ""); // Only allow digits

    if (digitsOnly.length >= 8 && digitsOnly.length <= 12) {
      setPhoneNumberError("");
    } else {
      setPhoneNumberError(languages[language].phoneError);
    }

    setFormData((prevState) => ({
      ...prevState,
      phone: {
        ...prevState.phone,
        number: digitsOnly, // Update the number field
      },
    }));
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Spread formData and create full_name
    const payload = {
      ...formData,
      full_name: `${formData.firstName} ${formData.lastName}`, // Add full_name
    };

    // Remove firstName and lastName from payload
    delete payload.firstName;
    delete payload.lastName;

    setLoading(true); // Show loader
    try {
      // Make the API request
      const response = await axios.post(
        "https://api.yafiapp.com/partner/user/submit_request",
        payload
      );

      // Handle success response
      console.log("API response:", response.data);

      setUploadedFileNames({});
      setFormData(defaultFormData);

      // Show success alert
      alert("Partner successfully registered!");
    } catch (error) {
      // Handle error response
      console.error("API request failed:", error);

      // Show error alert
      alert("Something went wrong. Please try again.");
    } finally {
      setLoading(false); // Hide loader
    }
  };

  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="header-inner-wrapper">
            <div className="site-logo">
              <a href="https://yafiapp.com">
                <img
                  src="https://yafiapp.com/wp-content/uploads/2024/04/cropped-logo.png"
                  alt="logo"
                />
              </a>
            </div>
            <div className="site-menu notranslate">
              <ul>
                <li>
                  <a href="https://yafiapp.com" id="menuHomeLabel">
                    {languages[language].home}
                  </a>
                </li>
                <li>
                  <a href="https://yafiapp.com/support/" id="menuSupportLabel">
                    {languages[language].support}
                  </a>
                </li>
                <li>
                  <a href="https://yafiapp.com/contact/" id="menuContactLabel">
                    {languages[language].contact}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <div className="main-body-area">
        <div className="container">
          {/* Language Selection */}
          <div className="language-selection mb-4">
            <select
              id="language"
              className="form-select"
              value={language}
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="es">Française</option>
            </select>
          </div>

          <h1 className="mb-4">{languages[language].partnerRegistration}</h1>
          {loading ? (
            <div className="loader-wrapper">
              <div className="spinner"></div>
            </div>
          ) : (
            <form
              id="userForm"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              {/* Business Owner Information */}
              <section className="mb-5">
                <h2 className="section-heading">
                  {languages[language].businessOwnerInfo}
                </h2>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="firstName" className="form-label">
                      {languages[language].firstName.title}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder={languages[language].firstName.description}
                      required
                    />
                  </div>
                  <div className="mb-3 col-md-6">
                    <label htmlFor="lastName" className="form-label">
                      {languages[language].lastName.title}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      placeholder={languages[language].lastName.description}
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="document" className="form-label">
                      {languages[language].uploadDocument.title}:
                    </label>
                    <div className="custom-file-uploader">
                      <input
                        type="file"
                        id="document"
                        name="document"
                        accept="image/png, image/jpeg, image/jpg, application/pdf"
                        onChange={(e) =>
                          handleFileChange(
                            e,
                            languages["en"].uploadDocument.title
                          )
                        }
                        style={{ display: "none" }} // Hide the default input
                      />
                      <button
                        type="button"
                        className="btn btn-primary file-upload-btn"
                        onClick={() =>
                          document.getElementById("document").click()
                        }
                        style={{ marginRight: "5px" }} // Hide the default input
                      >
                        {languages[language].uploadDocument.title}{" "}
                        {/* French for "Choose file" */}
                      </button>
                      <span id="owner-file" className="owner-file">
                        {uploadedFileNames[
                          languages["en"].uploadDocument.title
                        ] || languages[language].uploadDocument.description}
                      </span>
                    </div>
                  </div>
                </div>
              </section>

              {/* Business Information */}
              <section className="mb-5">
                <h2 className="section-heading">
                  {languages[language].businessInformation}
                </h2>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="businessName" className="form-label">
                      {languages[language].businessName}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="business_name"
                      name="business_name"
                      value={formData.vendor_details.business_name}
                      onChange={handleVendorDetailChange}
                      placeholder={`Enter your ${languages[language].businessName}`}
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="displayName" className="form-label">
                      {languages[language].displayName.title}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="display_name"
                      name="display_name"
                      value={formData.vendor_details.display_name}
                      onChange={handleVendorDetailChange}
                      placeholder={languages[language].displayName.description}
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="email" className="form-label">
                      {languages[language].email.title}:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.vendor_details.email}
                      onChange={handleVendorDetailChange}
                      placeholder={languages[language].email.description}
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="phone" className="form-label">
                      {languages[language].phone}:
                    </label>
                    <div className="input-group">
                      <span className="input-group-text p-0 border-remove">
                        <select
                          id="phoneCodes"
                          className="form-select"
                          name="country_code"
                          value={formData.phone.country_code}
                          onChange={handleCountryCodeChange} // Add onChange handler
                        >
                          {phoneCodes.map((code) => (
                            <option key={code} value={code}>
                              {code}
                            </option>
                          ))}
                        </select>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone.number} // Bind to phone.number
                        onChange={handlePhoneInputChange}
                        placeholder="e.g. (123) 456-7890"
                        required
                      />
                    </div>
                    <small id="phone-error" className="form-text text-danger">
                      {phoneNumberError}
                    </small>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="businessAddress" className="form-label">
                      {languages[language].businessAddress.title}:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="businessAddress"
                      name="street_address"
                      value={formData.vendor_details.address.street_address}
                      onChange={handleAddressChange}
                      placeholder={
                        languages[language].businessAddress.description
                      }
                      required
                    />
                  </div>

                  <div className="mb-3 col-md-6">
                    <label
                      htmlFor="additionalDescription"
                      className="form-label"
                    >
                      {languages[language].additionalDescription.title}:
                    </label>
                    <textarea
                      className="form-control"
                      id="additional_address"
                      name="additional_address"
                      value={formData.vendor_details.address.additional_address}
                      onChange={handleAddressChange}
                      placeholder={
                        languages[language].additionalDescription.description
                      }
                      required
                    ></textarea>
                  </div>

                  <div className="mb-3 col-md-6">
                    <label htmlFor="vendor_type" className="form-label">
                      {languages[language].vendor_type.title}:
                    </label>
                    <select
                      id="vendor_type"
                      className="form-select"
                      name="vendor_type"
                      value={formData.vendor_details.vendor_type}
                      onChange={handleVendorDetailChange}
                      required
                    >
                      <option value="">
                        {languages[language].selectBusinessType}
                      </option>
                      {language === "en" ? (
                        <>
                          <option value="hotel">Restaurant</option>
                          <option value="grocery">Store</option>
                          <option value="pharmacy">Pharmacy</option>
                        </>
                      ) : (
                        <>
                          <option value="hotel">Restaurant</option>
                          <option value="grocery">Magasin</option>
                          <option value="pharmacy">Pharmacie</option>
                        </>
                      )}
                    </select>

                    <small className="form-text text-muted">
                      {languages[language].vendor_type.description}
                    </small>
                  </div>

                  <section className="mb-5">
                    <h2 className="section-heading">
                      {languages[language].kycDocumentsTitle}
                    </h2>
                    <div className="row">
                      {/* Business Registration Card */}
                      <div className="mb-3 col-md-6">
                        <label
                          htmlFor="registrationCard"
                          className="form-label"
                        >
                          {languages[language].businessRegistrationCard.title}:
                        </label>
                        <div
                          id="registrationCardID"
                          className="custom-file-uploader"
                        >
                          <input
                            type="file"
                            id="registrationCard"
                            name="registrationCard"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                languages["en"].businessRegistrationCard.title
                              )
                            }
                            style={{ display: "none" }} // Hide the default input
                          />
                          <button
                            type="button"
                            className="btn btn-primary file-upload-btn"
                            onClick={() =>
                              document
                                .getElementById("registrationCard")
                                .click()
                            }
                            style={{ marginRight: "5px" }}
                          >
                            {languages[language].businessRegistrationCard.title}{" "}
                          </button>
                          <span className="registration-file">
                            {uploadedFileNames[
                              languages["en"].businessRegistrationCard.title
                            ] ||
                              languages[language].businessRegistrationCard
                                .description}
                          </span>
                        </div>
                      </div>

                      {/* Business Tax ID */}
                      <div className="mb-3 col-md-6">
                        <label htmlFor="taxId" className="form-label">
                          {languages[language].businessTaxId.title}:
                        </label>
                        <div className="custom-file-uploader">
                          <input
                            type="file"
                            id="taxId"
                            name="taxId"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                languages["en"].businessTaxId.title
                              )
                            }
                            style={{ display: "none" }} // Hide the default input
                          />
                          <button
                            type="button"
                            className="btn btn-primary file-upload-btn"
                            onClick={() =>
                              document.getElementById("taxId").click()
                            }
                            style={{ marginRight: "5px" }}
                          >
                            {languages[language].businessTaxId.title}{" "}
                          </button>
                          <span id="tax-file" className="tax-file">
                            {uploadedFileNames[
                              languages["en"].businessTaxId.title
                            ] || languages[language].businessTaxId.description}
                          </span>
                        </div>
                      </div>

                      {/* Tax Compliance Certificate */}
                      <div className="mb-3 col-md-6">
                        <label htmlFor="taxCompliance" className="form-label">
                          {languages[language].taxComplianceCertificate.title}:
                        </label>
                        <div className="custom-file-uploader">
                          <input
                            type="file"
                            id="taxCompliance"
                            name="taxCompliance"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                languages["en"].taxComplianceCertificate.title
                              )
                            }
                            style={{ display: "none" }} // Hide the default input
                          />
                          <button
                            type="button"
                            className="btn btn-primary file-upload-btn"
                            onClick={() =>
                              document.getElementById("taxCompliance").click()
                            }
                            style={{ marginRight: "5px" }}
                          >
                            {languages[language].taxComplianceCertificate.title}
                          </button>
                          <span
                            id="tax-compliance-file"
                            className="tax-compliance-file"
                          >
                            {uploadedFileNames[
                              languages["en"].taxComplianceCertificate.title
                            ] ||
                              languages[language].taxComplianceCertificate
                                .description}
                          </span>
                        </div>
                      </div>

                      {/* Business Banner Image */}
                      <div className="mb-3 col-md-6">
                        <label htmlFor="banner" className="form-label">
                          {languages[language].businessBannerImage.title}:
                        </label>
                        <div className="custom-file-uploader">
                          <input
                            type="file"
                            id="banner"
                            name="banner"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) =>
                              handleBannerFileUpload(
                                e,
                                languages["en"].businessBannerImage.title
                              )
                            }
                            className="hidden-file-input" // Apply the class here
                          />

                          <button
                            type="button"
                            className="btn btn-primary file-upload-btn"
                            onClick={() =>
                              document.getElementById("banner").click()
                            }
                            style={{ marginRight: "5px" }}
                          >
                            {languages[language].businessBannerImage.title}
                          </button>
                          <span id="banner-file" className="banner-file">
                            {uploadedFileNames[
                              languages["en"].businessBannerImage.title
                            ] ||
                              languages[language].businessBannerImage
                                .description}
                          </span>
                        </div>
                      </div>

                      {/* Profile Picture */}
                      <div className="mb-3 col-md-6">
                        <label htmlFor="profilePicture" className="form-label">
                          {languages[language].profilePicture.title}:
                        </label>
                        <div className="custom-file-uploader">
                          <input
                            type="file"
                            id="profilePicture"
                            name="profilePicture"
                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                languages["en"].profilePicture.title
                              )
                            }
                            style={{ display: "none" }} // Hide the default input
                          />
                          <button
                            type="button"
                            className="btn btn-primary file-upload-btn"
                            onClick={() =>
                              document.getElementById("profilePicture").click()
                            }
                            style={{ marginRight: "5px" }}
                          >
                            {languages[language].profilePicture.title}
                          </button>
                          <span
                            id="profile-picture-file"
                            className="profile-picture-file"
                          >
                            {uploadedFileNames[
                              languages["en"].profilePicture.title
                            ] || languages[language].profilePicture.description}
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>

              {/* Account Creation */}
              <section className="mb-5">
                <h2 className="section-heading">
                  {languages[language].accountCreation}
                </h2>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <label htmlFor="email" className="form-label">
                    {languages[language].accountEmailAddress}:
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={languages[language].email.description}
                      required
                    />
                  </div>

                  <PasswordField
                    formData={formData}
                    handleChange={handleChange}
                    languages={languages}
                    language={language}
                  />
                </div>
              </section>

              {/* Submit Button */}
              <button type="submit" className="btn btn-primary">
                {languages[language].submit}
              </button>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerRegistrationForm;
