export const languages = {
  en: {
    home: "Home",
    support: "Support",
    contact: "Contact",
    partnerRegistration: "PARTNER REGISTRATION FORM",
    businessOwnerInfo: "Business Owner Information",
    businessInformation: "Business Information",
    businessName: "Business name",
    firstName: { title: "Name", description: "Enter your first name" },
    lastName: { title: "Last name", description: "Enter your last name" },

    longitude: {
      title: "Longitude",
      description: "Enter the longitude coordinate",
    },
    latitude: {
      title: " Latitude",
      description: "Enter the lattitude coordinate",
    },
    uploadDocument: {
      title: "Upload document",
      description: "upload a color scan of owner valid ID or Passport",
    },
    uploadBannerImage: {
      title: "Banner Image",
      description: "upload Banner image",
    },
    displayName: {
      title: "Display name",
      description: "Business name to display on the customers app",
    },
    email: {
      title: "Email",
      description: "Enter your Email",
    },
    phone: "Phone",
    businessAddress: {
      title: "Business address",
      description:
        "Please retrieve your Google Maps Plus Pode and enter it here",
    },
    additionalDescription: {
      title: "Business address (additional description)",
      description: "Enter details about your location",
    },
    vendor_type: {
      title: "Business Type",
      description: "Restaurant, Store, Pharmacy",
    },
    selectBusinessType: "Select Business Type",
    accountCreation: "ACCOUNT CREATION",
    username: "Username",
    accountEmailAddress: "Account email address",
    password: {
      title: "Password",
      description: "Password",
    },
    confirmPassword: "Confirm Password",
    submit: "Submit",
    // KYC Documents Section
    kycDocumentsTitle: "Upload KYC documents",

    businessRegistrationCard: {
      title: "Business registration card",
      description: "upload a color scan of business registration",
    },
    businessTaxId: {
      title: "Business tax ID",
      description: "Upload a color scan of your business tax ID",
    },
    taxComplianceCertificate: {
      title: "Tax compliance certificate",
      description:
        "Upload a color scan of the valid tax compliance certificate",
    },
    businessBannerImage: {
      title: "Business banner image",
      description: "Upload picture of front of business during open hours",
    },
    profilePicture: {
      title: "Upload profile picture",
      description: "This is the image that will show on the customers app",
    },
  },
  es: {
    home: "Maison",
    support: "Soutien",
    contact: "Contact",
    partnerRegistration: "FORMULAIRE D'INSCRIPTION DU PARTENAIRE",
    businessOwnerInfo: "Informations du  Propriétaire de l'Entreprise",
    businessInformation: "Informations de l'Entreprise",
    firstName: { title: "Nom", description: "Entrez votre prénom" },
    lastName: {
      title: "Prénom",
      description: "Entrez votre nom de famille",
    },
    longitude: {
      title: "Longitude",
      description: "Entrez la coordonnée de longitude",
    },
    latitude: {
      title: " Latitude",
      description: "Entrez la coordonnée de latitude",
    },
    uploadDocument: {
      title: "Télécharger le document",
      description:
        "Téléchargez une copie couleur d'une pièce d'identité ou d'un passeport valide du propriétaire",
    },
    uploadBannerImage: {
      title: "Télécharger l'image de bannière",
      description: "Téléchargez l'image de la bannière",
    },
    businessName: "Nom de l'entreprise",
    displayName: {
      title: "Nom d'affichage",
      description:
        "Nom de l'entreprise à afficher sur l'application pour les clients",
    },
    email: {
      title: "E-mail",
      description: "Entrez votre email",
    },
    password: {
      title: "Mot de passe",
      description: "Mot de passe",
    },
    phone: "Téléphone",
    businessAddress: {
      title: "Adresse de l'entreprise",
      description:
        "Veuillez récupérer votre Plus Code Google Maps et l'entrer ici",
    },

    additionalDescription: {
      title: "Adresse de l'entreprise (description supplémentaire)",
      description: "Entrez les détails sur votre localisation",
    },
    vendor_type: {
      title: "Type d'entreprise",
      description: "Restaurant, Magasin, Pharmacie",
    },
    selectBusinessType: "Seleccionar Tipo de Negocio",
    accountCreation: "CRÉATION DE COMPTE",
    username: "Nombre de Usuario",
    accountEmailAddress: "Adresse e-mail du compte",
    confirmPassword: "Confirmar Contraseña",
    submit: "Soumettre",
    // KYC Documents Section
    kycDocumentsTitle: "Télécharger les documents KYC",

    businessRegistrationCard: {
      title: "Enregistrement de l'entreprise (RCCM)",
      description:
        "Téléchargez une copie couleur de l'enregistrement de l'entreprise au RCCM",
    },
    businessTaxId: {
      title: "Attestation d'immatriculation",
      description: "Téléchargez une copie couleur de votre identifiant fiscal",
    },
    taxComplianceCertificate: {
      title: "Certificat de conformité fiscale",
      description:
        "Téléchargez une copie couleur du certificat de conformité fiscale valide",
    },
    businessBannerImage: {
      title: "Image de la bannière de l'entreprise",
      description:
        "Téléchargez une photo de l'extérieur de l'entreprise pendant les heures d'ouverture",
    },
    profilePicture: {
      title: "Télécharger la photo de profil",
      description:
        "C'est l'image qui sera affichée sur l'application pour les clients",
    },
  },
};

export const phoneCodes = [
  "+1",
  "+7",
  "+20",
  "+27",
  "+30",
  "+31",
  "+32",
  "+33",
  "+34",
  "+36",
  "+39",
  "+40",
  "+41",
  "+43",
  "+44",
  "+45",
  "+46",
  "+47",
  "+48",
  "+49",
  "+51",
  "+52",
  "+53",
  "+54",
  "+55",
  "+56",
  "+57",
  "+58",
  "+60",
  "+61",
  "+62",
  "+63",
  "+64",
  "+65",
  "+66",
  "+81",
  "+82",
  "+84",
  "+86",
  "+90",
  "+91",
  "+92",
  "+93",
  "+94",
  "+95",
  "+98",
  "+211",
  "+212",
  "+213",
  "+216",
  "+218",
  "+220",
  "+221",
  "+222",
  "+223",
  "+224",
  "+225",
  "+226",
  "+227",
  "+228",
  "+229",
  "+230",
  "+231",
  "+232",
  "+233",
  "+234",
  "+235",
  "+236",
  "+237",
  "+238",
  "+239",
  "+240",
  "+241",
  "+242",
  "+243",
  "+244",
  "+245",
  "+246",
  "+248",
  "+249",
  "+250",
  "+251",
  "+252",
  "+253",
  "+254",
  "+255",
  "+256",
  "+257",
  "+258",
  "+260",
  "+261",
  "+262",
  "+263",
  "+264",
  "+265",
  "+266",
  "+267",
  "+268",
  "+269",
  "+290",
  "+291",
  "+297",
  "+298",
  "+299",
  "+350",
  "+351",
  "+352",
  "+353",
  "+354",
  "+355",
  "+356",
  "+357",
  "+358",
  "+359",
  "+370",
  "+371",
  "+372",
  "+373",
  "+374",
  "+375",
  "+376",
  "+377",
  "+378",
  "+380",
  "+381",
  "+382",
  "+383",
  "+385",
  "+386",
  "+387",
  "+389",
  "+420",
  "+421",
  "+423",
  "+500",
  "+501",
  "+502",
  "+503",
  "+504",
  "+505",
  "+506",
  "+507",
  "+508",
  "+509",
  "+590",
  "+591",
  "+592",
  "+593",
  "+595",
  "+597",
  "+598",
  "+599",
  "+670",
  "+672",
  "+673",
  "+674",
  "+675",
  "+676",
  "+677",
  "+678",
  "+679",
  "+680",
  "+681",
  "+682",
  "+683",
  "+685",
  "+686",
  "+687",
  "+688",
  "+689",
  "+690",
  "+691",
  "+692",
  "+993",
  "+994",
  "+995",
  "+996",
  "+998",
];

export const defaultFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: {
    country_code: "+1",
    number: "",
  },
  kyc: { documents: [] },
  password: "",

  vendor_details: {
    email: "",
    display_name: "",
    business_name: "",
    banner_image: "",
    address: {
      street_address: "",
      additional_address: "",
      coordinates: { latitude: "", longitude: "" },
    },
    vendor_type: "",
  },
};
